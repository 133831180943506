// This variable file will change per group website.
// Together with the scss variables these are really the only two files you should change.

// Enable/Disable API call caching. This should be enabled on the live server, but for development purposes it might be handy to turn it off.
export const apiCaching = true;

// This variable will be added to each page's HTML <title> tag. The existing value which should be unique per page, will be placed in the {pageTitle} tag.
// A | symbol will automatically be inserted between the {pagetitle} tag and the rest of the string.
export const htmlHeadTitle = '{pageTitle} John Stronach Ltd - Shipping Agent';

window.addCompaniesToFooter = function(container) {
  document.querySelectorAll(container).forEach ((menuItem) => {

    menuItem.insertAdjacentHTML('beforeend', '<a href="" class="nav-item"></a>');
    menuItem.insertAdjacentHTML('beforeend', '<a href="" class="nav-item"></a>');
    menuItem.insertAdjacentHTML('beforeend', '<a href="" class="nav-item"></a>');
    menuItem.insertAdjacentHTML('beforeend', '<a href="" class="nav-item"></a>');
    menuItem.insertAdjacentHTML('beforeend', '<a href="" class="nav-item"></a>');
  });
};

// Menu, used for both the footer and the main menu.
// Sub items are always loaded with AJAX, as such define the container name, and write a function in the onLoad.
// Setting href to null, will create a <div> element rather than an <a>
export const menu = [
  { 'name': 'About Us', 'href': '/index.html#about-us', 'show': true, 'inGetToKnowUs': false, 'sub': null },
  { 'name': 'Locations', 'href': '/index.html#locations', 'show': true, 'inGetToKnowUs': false, 'sub': { 'function': 'addCompaniesToFooter' } },
  { 'name': 'Privacy Notice', 'href': '/privacy-notice.html', 'show': false, 'inGetToKnowUs': false, 'sub': null },
  { 'name': 'Cookie Statement', 'href': '/cookie-statement.html', 'show': false, 'inGetToKnowUs': false, 'sub': null },
  { 'name': 'Corporate Responsibility', 'href': '/corporate-responsibilities.html', 'show': false, 'inGetToKnowUs': true, 'sub': null },
  { 'name': 'Trading Terms', 'href': '/trading-terms.html', 'show': false, 'inGetToKnowUs': true, 'sub': null },
];

// The home page has a carousel in the hero. Define the images to show and the text to print underneath.
// If you only specify 1 single entry, there won't be a carousel. Just the one image will be shown.
// In order for the compiler to add the right image`s to the dist folder make sure you import them, and then use the
// imported variable as the image source.

import heroSlide1 from '../img/AdobeStock_277739087.jpg';
export const heroCarousel = [
  { 'img': heroSlide1, 'style' : 'object-position: 20% 50%;', 'label': '' }
];

// API information, make sure the domain ends with a /
export const api = {
  'domain': 'https://api.graypengroup.com/v1/',
  'company': 'JNS',
  'referrer': 'John Stronach'
};

export const removeFromOurGroupOfCompanies = [
  'John Stronach'
];

// How many news items will be rendered as default. The API returns the latest 10, thus you can't pick a number larger than this.
export const maxNewsItems = 5;

export const joinTheConversationBoxColours = [ '#54A4C7', '#205F7A', '#009de2' ];

export const gTagKey = 'G-NF518ZFKZW';

// Remove Quality Policy for company
export const removeQualityPolicy = true;

export const hideNews = false;

// Locations page settings.
import mapMarkerImage from '../img/map-marker.png';
export const locations = {
  'mapMarkerImage': mapMarkerImage
};

// Company information which is displayed in the footer.
export const company = {
  'legalName': 'John Stronach Limited',
  'registeredInCountry': 'England',
  'registrationNumber': '05294797',
  'registeredAddress': 'Registered Office<br>The Bridge, One Graypen Way,<br>Queens Road, Immingham,<br>DN40 1QN, United Kingdom'
};